<template>
  <v-form
    ref="form"
    v-model="isValid"
    @submit.prevent="submit"
  >
    <CheckTextField
      v-if="showEmail"
      ref="email"
      v-model="formData.emailAddress"
      :label="$t('labels.invoice-email')"
      :rules="[rules.required, rules.email]"
      required
      outlined
      :validate-on-input="validateOnInput"
      @click="onClick('Invoice Email Address', formData.emailAddress)"
      @blur="handleBlur"
    />

    <CheckTextField
      ref="firstName"
      v-model="formData.firstName"
      :label="$t('labels.first-name')"
      :rules="[rules.required]"
      required
      outlined
      :validate-on-input="validateOnInput"
      @click="onClick('First Name', formData.firstName)"
      @blur="handleBlur"
    />

    <CheckTextField
      ref="lastName"
      v-model="formData.lastName"
      :label="$t('labels.last-name')"
      :rules="[rules.required]"
      required
      outlined
      :validate-on-input="validateOnInput"
      @click="onClick('Last Name', formData.lastName)"
      @blur="handleBlur"
    />

    <CheckTextField
      v-if="showAddressLine1"
      ref="addressLine1"
      v-model="formData.address.addressLine1"
      :label="$t('labels.address-suffix')"
      outlined
      :validate-on-input="validateOnInput"
      @click="onClick('Address Suffix', formData.address.addressLine1)"
      @blur="handleBlur"
    />

    <v-row no-gutters>
      <v-col
        cols="12"
        sm="9"
      >
        <CheckTextField
          ref="street"
          v-model="formData.address.street"
          :label="$t('labels.street')"
          :rules="[rules.required]"
          required
          outlined
          :validate-on-input="validateOnInput"
          @click="onClick('Street', formData.address.street)"
          @blur="handleBlur"
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
        class="pl-sm-2"
      >
        <CheckTextField
          ref="houseNumber"
          v-model="formData.address.houseNumber"
          :label="$t('labels.house-number')"
          :rules="[rules.required, rules.length({ min: 1, max: 10 })]"
          required
          outlined
          :validate-on-input="validateOnInput"
          @click="onClick('House No.', formData.address.houseNumber)"
          @blur="handleBlur"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
      >
        <CheckTextField
          ref="postalCode"
          v-model="formData.address.postalCode"
          :label="$t('labels.zip')"
          :rules="[rules.required, rules.zip]"
          required
          outlined
          :validate-on-input="validateOnInput"
          @click="onClick('ZIP', formData.address.postalCode)"
          @blur="handleBlur"
        />
      </v-col>

      <v-col
        cols="12"
        sm="9"
        class="pl-sm-2"
      >
        <CheckTextField
          ref="city"
          v-model="formData.address.city"
          :label="$t('labels.city')"
          :rules="[rules.required]"
          required
          outlined
          :validate-on-input="validateOnInput"
          @click="onClick('City', formData.address.city)"
          @blur="handleBlur"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col
        v-if="paymentMethodItems.length > 1 && !hasPaymentProvider"
        cols="12"
        :sm="showCouponField ? 6 : 12"
      >
        <v-select
          ref="paymentMethod"
          v-model="formData.paymentMethod"
          :items="paymentMethodItems"
          :label="$t('labels.payment-method')"
          required
          outlined
          @click="onClick('Payment method', formData.paymentMethod)"
        />
      </v-col>

      <v-col
        v-if="showCouponField"
        cols="12"
        :sm="paymentMethodItems.length > 1 ? 6 : 12"
      >
        <CheckTextField
          ref="couponCode"
          v-model="formData.couponCode"
          :label="$t('labels.coupon-code')"
          outlined
          :rules="previewOrder?.couponRules"
          :validate-on-input="validateOnInput"
          @click="onClick('Coupon', formData.couponCode)"
          @blur="handleCouponCodeBlur"
        />
      </v-col>
    </v-row>

    <v-select
      v-if="showCountry"
      ref="country"
      v-model.lazy="formData.address.country"
      outlined
      label="Land"
      :disabled="disableCountrySelection"
      :validate-on-input="validateOnInput"
      :items="availableCountryItems"
      :rules="[rules.required]"
      @click="onCountryClick('Country', formData.address.country)"
      @blur="handleCountryBlur()"
      @change="onCountryChange(formData.address.country)"
    />

    <Feature
      v-if="showIsCompany"
      v-slot="{ feature: { config: { isCompanyOptional } }}"
      :feature-slug="featureNames.SIGNUP"
    >
      <v-row
        v-if="isCompanyOptional"
        no-gutters
        :class="{'mb-8': !isCompany}"
      >
        <v-col>
          <v-checkbox
            ref="isCompany"
            v-model.lazy="isCompany"
            class="mt-0 mb-1"
            outlined
            hide-details
            :label="$t('labels.register-as-company')"
            @click="onClick('Register as a company', isCompany)"
          />
        </v-col>
      </v-row>
    </Feature>

    <v-row
      v-if="isCompany"
      no-gutters
      class="mb-2"
    >
      <v-col>
        <CheckTextField
          ref="companyName"
          v-model="formData.companyName"
          :label="$t('labels.company')"
          :rules="isCompany ? [rules.required]: []"
          :required="isCompany"
          outlined
          :validate-on-input="validateOnInput"
          @click="onClick('Company', formData.companyName)"
          @blur="handleBlur"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="isVATRequired"
      no-gutters
      align-sm="baseline"
    >
      <v-col>
        <AutomaticInlineExposure orientation="left">
          <template
            slot="field"
            slot-scope="{ events: { showTooltip, hideTooltip } }"
          >
            <CheckTextField
              ref="vatId"
              v-model="formData.vatId"
              :label="$t('labels.vat-number')"
              :placeholder="vatPlaceholder"
              :rules="isVATRequired ? [rules.vatId, rules.required] : []"
              persistent-hint
              outlined
              :validate-on-input="validateOnInput"
              @focus="showTooltip"
              @blur="() => { hideTooltip(); handleBlur() }"
              @click="onClick('VAT ID', formData.vatId)"
              @input="updateVatId"
            />
          </template>
          <template slot="tooltipContent">
            <div>
              <div class="pb-2">
                {{ vatTooltipFormat }}
              </div>
              <div>{{ vatTooltipRequest }}</div>
              <a
                v-if="vatRequestLink"
                :href="vatRequestLink"
                target="_blank"
                style="cursor:pointer"
              >{{ $t('billwerk.customer-form.vat-request.link-text') }}</a>
            </div>
          </template>
        </AutomaticInlineExposure>
      </v-col>
    </v-row>
    <LoadingButton
      v-if="!immediateSubmit && !hideSubmit"
      type="submit"
      :color="isPlatformMarkero ? $vuetify.theme.themes.light.markero.blue : 'primary'"
      class="w-full mb-2"
      :style="{'color': isPlatformMarkero ? 'white' : ''}"
      :loading="isSubmitting"
    >
      {{ submitButtonText }}
    </LoadingButton>
  </v-form>
</template>

<script>
import { zip, empty, length, required, email } from '@/lib/validation'
import LoadingButton from '@/components/loading-button.vue'
import CheckTextField from '@/components/forms/CheckTextField'
import AutomaticInlineExposure from '@/components/AutomaticInlineExposure'
import brandingMixin from '@/mixins/branding'
import feature from '@/mixins/feature'
import { EUCountries } from '@/modules/billwerk/euCountries'
import countries from '@/mixins/countries'

const VAT_ID_REGX = /^(ATU[0-9]{8}|DE[0-9]{9})$|^((CHE)(-|\s)?)\d{3}(\.|\s)?\d{3}(\.|\s)?\d{3}(\s)?(IVA|TVA|MWST|VAT)?$/

const vatPlaceholderForCountry = {
  de: 'DE123456789',
  ch: 'CHE-123.456.789',
  at: 'ATU12345678'
}

const vatRequestLinks = {
  de: 'https://www.formulare-bfinv.de/ffw/action/invoke.do?id=ustid',
  at: 'https://www.usp.gv.at/steuern-finanzen/umsatzsteuer/umsatzsteuer-identifikationsnummer.html#ZumFormular'
}

const paymentMethodLabels = {
  'CreditCard:Reepay': 'settings.payment.payment-method.methods.credit-card',
  'ApplePay:Reepay': 'settings.payment.payment-method.methods.apple-pay',
  'Debit:Reepay': 'settings.payment.payment-method.methods.debit'
}

export default {
  components: {
    LoadingButton,
    CheckTextField,
    AutomaticInlineExposure
  },
  mixins: [brandingMixin, feature, countries],
  props: {
    previewOrder: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      required: true
    },
    paymentMethods: {
      type: Array,
      default: () => []
    },
    isSubmitting: {
      type: Boolean,
      default: false
    },
    hasPaymentProvider: {
      type: Boolean,
      default: false
    },
    showCouponField: {
      type: Boolean,
      default: true
    },
    submitButtonText: {
      type: String,
      default: 'Weiter'
    },
    immediateSubmit: {
      type: Boolean,
      default: false
    },
    showEmail: {
      type: Boolean,
      default: false
    },
    showAddressLine1: {
      type: Boolean,
      default: false
    },
    showIsCompany: {
      type: Boolean,
      default: false
    },
    hideSubmit: {
      type: Boolean,
      default: false
    },
    disableCountrySelection: {
      type: Boolean,
      default: false
    },
    showCountry: {
      type: Boolean,
      default: false
    },
    validateOnInput: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isVatTooltipActive: false,
      isValid: false,
      isCompany: true,
      formData: {
        firstName: '',
        lastName: '',
        companyName: '',
        vatId: '',
        couponCode: '',
        paymentMethod: '',
        isCompany: true,
        address: {
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          country: ''
        }
      },

      rules: {
        zip,
        length,
        required,
        email,
        vatId: (value) => (
          empty(value) ||
          VAT_ID_REGX.test(value.replace(/\s/g, '')) ||
          this.$t('billwerk.customer-form.vat-hint')
        )
      }
    }
  },

  computed: {
    isVATRequired () {
      return this.isCompany && (!this.isPlatformMarkero || this.isEUCountry)
    },
    isEUCountry () {
      return EUCountries.includes(this.formData.address.country)
    },
    paymentMethodItems () {
      return this.paymentMethods
        .filter((method) => {
          // Show Apple Pay only if supported
          return method !== 'ApplePay:Reepay' || window.ApplePaySession
        })
        .map((method) => ({
          text: this.$t(paymentMethodLabels[method]),
          value: method
        }))
    },

    vatTooltipRequest () {
      return this.vatRequestLink ? this.$t('billwerk.customer-form.vat-request.question') : ''
    },

    vatTooltipFormat () {
      const country = this.formData.address.country
      const structure = {
        de: this.$t('billwerk.customer-form.tooltip-structure.de'),
        ch: this.$t('billwerk.customer-form.tooltip-structure.ch'),
        at: this.$t('billwerk.customer-form.tooltip-structure.at')
      }[country.toLowerCase()]
      return this.$t('billwerk.customer-form.tooltip-structure.final', {
        structure,
        placeholder: this.vatPlaceholder
      })
    },

    vatPlaceholder () {
      const country = this.formData.address.country
      return vatPlaceholderForCountry[country.toLowerCase()]
    },

    vatRequestLink () {
      const country = this.formData.address.country
      return vatRequestLinks[country.toLowerCase()]
    },

    showVatTooltip () {
      return this.isVatTooltipActive
    },

    isVatIdComplete () {
      return this.formData.vatId && this.$refs.vatId?.isComplete
    }
  },

  watch: {
    value: {
      handler (newVal) {
        this.formData = newVal
        if (this.isCompany === undefined) {
          this.isCompany = Boolean(!this.immediateSubmit || this.value.vatId)
        }
      },
      deep: true
    },

    isCompany: {
      handler () {
        this.formData.isCompany = this.isCompany
        this.$emit('update-preview')
        if (!this.isCompany) {
          if (this.immediateSubmit) {
            this.submit()
          }
        }
      }
    },

    formData: {
      handler (newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },

    isVatIdComplete (isComplete) {
      this.$emit('update-preview')
      if (isComplete) {
        this.isVatTooltipActive = false
      }
    }
  },

  mounted () {
    this.formData = this.value
    const isCompanyOptional = this.$features.feature(this.featureNames.SIGNUP).config.isCompanyOptional
    this.isCompany = this.immediateSubmit ? undefined : !isCompanyOptional
    this.formData.isCompany = this.immediateSubmit ? undefined : !isCompanyOptional
    window.scrollTo(0, 0)
  },

  methods: {
    onClick (label, value) {
      this.$tracking.event('Payment', 'Clicked', label, value)
    },
    onCountryClick (label, value) {
      this.$tracking.event('Payment', 'Clicked', 'Country', 'Open')
      this.onClick(label, value)
    },
    handleBlur () {
      if (this.immediateSubmit) this.submit()
    },
    handleCountryBlur () {
      this.$tracking.event('Payment', 'Clicked', 'Country', 'Close')
      this.handleBlur()
    },
    onCountryChange (value) {
      this.$tracking.event('Payment', 'Selected', 'Country', value)
      this.$emit('update-preview')
    },
    handleCouponCodeBlur () {
      if (this.isPlatformMarkero) {
        this.$emit('update-preview')
      }

      this.handleBlur()
    },

    updateVatId (value) {
      this.formData.vatId = value.toUpperCase()
    },

    submit () {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
      } else {
        this.validateAndTrack()
      }
    },
    validateAndTrack () {
      const fields = [
        { ref: 'firstName', label: 'Data Valid First Name' },
        { ref: 'lastName', label: 'Data Valid Last Name' },
        { ref: 'companyName', label: 'Data Valid Company Name' },
        { ref: 'vatId', label: 'Data Valid Vat Id' },
        { ref: 'street', label: 'Data Valid Street' },
        { ref: 'houseNumber', label: 'Data Valid House Number' },
        { ref: 'postalCode', label: 'Data Valid Postal Code' },
        { ref: 'city', label: 'Data Valid City' },
        { ref: 'couponCode', label: 'Data Valid Coupon Code' }
      ]

      fields.forEach(({ ref, label }) => {
        const fieldRef = this.$refs[ref]
        if (fieldRef && !fieldRef.isComplete) {
          this.trackInvalidField(label, fieldRef.model)
        }
      })
    },

    trackInvalidField (label, value) {
      this.$tracking.event(
        'Payment',
        'Check',
        label,
        value
      )
    }

  }
}
</script>
